import React, { useState } from 'react';
import './App.css';
import './index.css'
import Modal from 'react-modal';
import { AiFillHome } from 'react-icons/ai'
import { push, ref } from "firebase/database";
import { dBase } from './firebaseConection'
import InputMask from 'react-input-mask';


interface FormData {
  name: string;
  dtBirth: string;
  phone: string;
  email: string;
  spouseName: string;
  spouseDtBirth: string;
  spousePhone: string;
  spouseEmail: string;
  dtWedding: string
}

Modal.setAppElement('#root'); // Define o elemento raiz do aplicativo para acessibilidade do modal

function App() {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    dtBirth: '',
    phone: '',
    email: '',
    spouseName: '',
    spouseDtBirth: '',
    spousePhone: '',
    spouseEmail: '',
    dtWedding: ''
  });

  const [enviado, setEnviado] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [termosAceitos, setTermosAceitos] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let dt = ''
    // if(name ==='dtWedding'){
    //   dt = new Date(value)
    // }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!termosAceitos) {
      alert('Você deve aceitar os termos para continuar.');
      return;
    }

    // Validando os campos Nome do Esposo, Nome da Esposa e Data de Casamento
    if (formData.name.length < 2 || formData.spouseName.length < 2) {
      alert('Os nomes do esposo e da esposa devem ter pelo menos 2 caracteres.');
      return;
    }

    if (!formData.dtWedding.match(/^\d{4}-\d{2}-\d{2}$/)) {
      alert('A data de casamento deve ser informada no formato AAAA-MM-DD.');
      return;
    }
    console.log(formData.dtWedding)

    const dataRef = ref(dBase, 'Couples')

     push(dataRef, { ...formData, name: formData.name.toUpperCase(), spouseName: formData.spouseName.toUpperCase(), dtInsert: new Date().toString(), dtWedding: new Date(formData.dtWedding + 'T00:00:00.001-03:00').toString() })

    // Você pode fazer alguma ação aqui, como enviar os dados para um sistema de RSVP.
    // Neste exemplo, apenas definimos o estado 'enviado' como verdadeiro.
    setEnviado(true);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <><div className="admin-container">
      <header className="admin-header">
        <nav className='nav-header'>
          {/* <button onClick={handleLogOut}>
        <BiLogOut size={28} color="#DB2629" />
    </button> */}
          
            <AiFillHome size={28} />
          

        </nav>
      </header>
      <h1 className='logo'>Ministério<span className='logo-text'>Família</span></h1>
      
        <h1>Renovação de Votos Matrimoniais</h1>
        <p>Data do Evento: 03/12/2023</p>
        {!enviado && ( <><br/>
        <p>
          Para participar do evento, digite as informações:
        </p>
        <form onSubmit={handleSubmit} className="form">
          
            <label className="label" htmlFor="name">Nome do Esposo:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required style={{ width: '100%', padding: '10px' }} className="custom-input" />
          
          
            <label className="label" htmlFor="spouseName">Nome da Esposa:</label>
            <input
              type="text"
              id="spouseName"
              name="spouseName"
              value={formData.spouseName}
              onChange={handleInputChange}
              required style={{ width: '100%', padding: '10px' }} className="custom-input" />
          
          
            <div className="input-row">
  <div className="input-group">
    <label className="label" htmlFor="spousePhone">Telefone:</label>
    <InputMask
  mask="(99) 99999-9999"
  id="spousePhone"
  name="spousePhone"
  value={formData.spousePhone}
  onChange={handleInputChange}
  
  placeholder="(00) 00000-0000"
  className="custom-input"
/>
  </div>
  <div className="input-group">
    <label className="label" htmlFor="dtWedding">Data do Casamento:</label>
    <input
      type="date"
      id="dtWedding"
      name="dtWedding"
      value={formData.dtWedding}
      onChange={handleInputChange}
      required className="custom-input"
    />
  </div>
</div>
          
          
            <label>
              <input
                type="checkbox"
                checked={termosAceitos}
                onChange={() => setTermosAceitos(!termosAceitos)}
                required />{' '}
              Aceito os <a onClick={openModal}>Termos e Condições</a>
            </label>
          <br/>
          <button  className='btn-register' type="submit">Gravar</button>
        </form></>)}
        {enviado && (
          <><br/><br/>
          <p>
            Obrigado! Seus dados foram gravados com sucesso.
          </p>
          </>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Termos e Condições"
        ><button onClick={closeModal}>Fechar</button>
          <div className="markdown prose w-full break-words dark:prose-invert light">
          <h2>Termos e Condições</h2>
          <p><strong>TERMO DE CONSENTIMENTO PARA COLETA DE DADOS PESSOAIS</strong></p>

          <p>Através deste termo, o usuário, doravante denominado(a) o "Titular dos Dados", concede seu consentimento livre, informado e inequívoco à empresa IASD CENTRAL SÃO JOSÉ DOS CAMPOS, doravante denominada a "Controladora dos Dados", para coletar e processar seus dados pessoais, conforme os termos abaixo:</p>

          <p><strong>1. Dados Pessoais Coletados:</strong>
            A Controladora dos Dados está autorizada a coletar e registrar os seguintes dados pessoais do Titular dos Dados:</p>

          <ul><li>Nome</li><li>Telefone</li></ul>
          <p><strong>2. Finalidade da Coleta:</strong>
            Os dados pessoais acima mencionados serão coletados com a finalidade exclusiva de gerenciar a participação do Titular dos Dados no evento de renovação de votos matrimoniais que ocorrerá em 02/12/2023.</p>

          <p><strong>3. Compartilhamento de Dados:</strong>
            Os dados pessoais coletados não serão compartilhados com terceiros, exceto quando necessário para o cumprimento da finalidade acima mencionada ou quando exigido por lei.</p>

          <p><strong>4. Armazenamento e Prazo de Retenção:</strong>
            Os dados pessoais do Titular serão mantidos pela Controladora dos Dados pelo tempo necessário para atender à finalidade da coleta. Após o término do evento, os dados serão devidamente excluídos.</p>

          <p><strong>5. Direitos do Titular dos Dados:</strong>
            Como Titular dos Dados, o mesmo tem o direito de solicitar à Controladora dos Dados o acesso, retificação, exclusão ou portabilidade de seus dados pessoais. Também tem o direito de revogar este consentimento a qualquer momento, mediante solicitação à Controladora dos Dados.</p>

          <p><strong>6. Segurança dos Dados:</strong>
            A Controladora dos Dados adotará medidas de segurança adequadas para proteger os dados pessoais do Titular contra acesso não autorizado ou uso indevido.</p>

          <p><strong>7. Lei Aplicável:</strong>
            Este termo está em conformidade com a Lei Geral de Proteção de Dados (LGPD) do Brasil.</p>

          <p><strong>8. Consentimento Voluntário:</strong>
            Titular dos Dados declara que este consentimento é dado de forma voluntária e consciente, após ter sido devidamente informado sobre a coleta e o tratamento de seus dados pessoais.</p>

          São José dos Campos/SP
          </div>
        </Modal>
      </div></>
  );
}

export default App;

import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database'

//config
const firebaseConfig = {
    apiKey: "AIzaSyAsj67bu9a0cQNfNoPJYDGtKoUSL7euMeg",
    authDomain: "minfamilia-60bf5.firebaseapp.com",
    databaseURL: "https://minfamilia-60bf5-default-rtdb.firebaseio.com",
    projectId: "minfamilia-60bf5",
    storageBucket: "minfamilia-60bf5.appspot.com",
    messagingSenderId: "696989368465",
    appId: "1:696989368465:web:5a2ee938a93376f3913f30",
    measurementId: "G-V67FHDYBMK"
};

// Initialize Firebase
const firebaseapp = initializeApp(firebaseConfig);

//const db = root<Database>(getFirestore(firebaseapp) as any)  
const dBase = getDatabase(firebaseapp)

export { dBase };